import React, { useState, useEffect } from "react";

// import { useQuery } from "@apollo/client"
// import gql from "graphql-tag"
// import { graphql, navigate } from "gatsby"

import loadable from "@loadable/component";
 
// import Header from "../components/Header/Header"
// import PropertyBar from "../components/PropertyHeader/PropertyHeader"
// import PropertyBanner from "../components/PropertyBanner/PropertyBanner"
// import PropertyInfo from "../components/PropertyInfo/PropertyInfo"
// import PropertyDetails from "../components/PropertyDescription/PropertyDescription"
// import ContactCard from "../components/ContactSidebar/PropertySidebar"
// import Facility from "../components/Facilities/Facilities"
// import SimilarProperty from "../components/SimilarProperty/Property"
// import Footer from "../components/Footer/Footer"

import { navigate } from "gatsby"
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss"
import "../scss/grunticon.scss"
import "../pages/PropertyDetails/PropertyDetails.scss"
import PropertyMap from "../components/PropertyMap/PropertyMap"
// import AboutProperty from "../components/AboutProperty/AboutProperty"
// import Btn from "../components/BottomBtn/Btn"
import { useWindowSize } from "../hooks/window-size"
// import MortgageRepayments from "../components/MortgageRepayments/MortgageRepayments"
// import RentalYield from "../components/MortgageRepayments/RentalYield"
// import { AnimationOnScroll } from "react-animation-on-scroll"
// import Help from "../components/Help/Help"
import SEO from "../components/Seo/seo";
import Sticky from "react-stickynode"
import { toTitleCase } from "../components/property-search/utils"
// import Property404 from "../pages/Property404"
import useDeviceMedia from "../hooks/useDeviceMedia"

import {
  UserObjectStoreProvider,
  useAuthState,
  getUserobjectData,
} from "@starberry/myaccount-website-utils"
import Footer from "../components/Footer/Footer";

const Header = loadable(() => import("../components/Header/Header"));
const PropertyBanner = loadable(() => import("../components/PropertyBanner/PropertyBanner"));
const PropertyBar = loadable(() => import("../components/PropertyHeader/PropertyHeader"));
const PropertyInfo = loadable(() => import("../components/PropertyInfo/PropertyInfo"));
const PropertyDetails = loadable(() => import("../components/PropertyDescription/PropertyDescription"));
const Facility = loadable(() => import("../components/Facilities/Facilities"));
const AboutProperty = loadable(() => import("../components/AboutProperty/AboutProperty"));
const ContactCard = loadable(() => import("../components/ContactSidebar/PropertySidebar"));
const SimilarProperty = loadable(() => import("../components/SimilarProperty/Property"));
const MortgageRepayments = loadable(() => import("../components/MortgageRepayments/MortgageRepayments"));
const RentalYield = loadable(() => import("../components/MortgageRepayments/RentalYield"));
const Help = loadable(() => import("../components/Help/Help"));


const STAGE_ENV = process.env.GATSBY_STRAPI_GGFX_ENV || "i.dev"

// const PROPERTY_DETAILS = gql`
//   query GetProperty($id: String!) {
//     properties(where: { id: $id, publish:true}) {
//       id
//       slug
//       crm_id
//       status
//       bedroom
//       bathroom
//       building
//       community
//       address
//       amenities
//       size
//       title
//       price
//       price_on_request
//       price_qualifier
//       listing_id
//       virtual_tour
//       department
//       search_type
//       negotiator_details
//       price_qualifier
//       display_address
//       sub_community
//       description
//       long_description
//       latitude
//       longitude
//       images
//       imagetransforms
//       ghost_property
//       extra
//       city
//     }
//   }
// `

// markup
const PropertyInfoComponent = props => {
  const [windowWidth] = useWindowSize()
  const [prseometachk, setPrseometachk] = React.useState(false)
  
  const { isMobile, isTablet } = useDeviceMedia()
  const bannerHeight = isMobile ? 260 : 635
  const detailHeight = isMobile ? 3800 : 3000

  const [renderComponent, setRenderComponent] = useState(false);

  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })

  }, [])

  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  // let myid_filt = pageurl.split("?")
  // let mypageurl = myid_filt[0]
  // let property_slug = mypageurl.split("-")
  // let id = property_slug[property_slug.length - 1]
  // id = id.replace("/", "")
  // console.log("props",props)


  // const {
  //   loading,
  //   error,
  //   data: property_details,
  // } = useQuery(PROPERTY_DETAILS, {
  //   variables: { id: id },
  // })



  let whatsAppMessage = ""
  var metatitle = ""
  var metadesc = ""
  var seo_image_url = ""
  const urlSections = pageurl.split("/")

  let propertyData = props.pageContext.data;
  let data = props.pageContext.data;
  if(data?.ghost_property){
    navigate(`/property-details/${data?.slug}-${data?.id}`, {
      replace: true,
    })
  }  

    whatsAppMessage = `Hello, I would like to get more information about this property you posted on ${urlSections[2]}: Reference: ${propertyData?.listing_id} Type: ${propertyData?.building} Price: ${propertyData?.price} AED/${propertyData?.price_qualifier} Location: ${propertyData?.community} Link: ${pageurl}`

  // React.useEffect(() => {

  // }, [prseometachk])

  // Loading logo
  // if (loading)
  //   return (
  //     <section className={"loader-wrapper"}>
  //       <div id="loader-wrapper">
  //         <div id="loader" class="new-loader">
  //           <img 
  //             src={BrandLogo}
  //             className="logo-white loader-logo"
  //             alt="logo"
  //           />
  //         </div>
  //       </div>
  //     </section>
  //   )
  // Loading logo

  // var properties = null;
  // if (property_details?.properties.length > 0) {
  //   properties = property_details?.properties[0];
  // }
  // if (properties === null) {
  //   navigate('/property/for-sale/')
  // }

  //console.log("property_detailsproperty_details", property_details && property_details.properties.length > 0 &&  property_details,id)
  const buildingType = ['land' , 'whole-building']

  var search_action="sale"
  if(data?.search_type =="lettings") {
    search_action = "rent";
  }
  //console.log("bedroom",data?.bedroom)
 
  let building = data?.building;
  let strCheck = typeof building === 'string' ? building.toLowerCase().replace(' ', '') : null
  let isBuilding = buildingType.includes(strCheck)
  metatitle = toTitleCase(data?.building)+" for "+search_action+ `${data?.bedroom > 0 ? isBuilding ? ' in ' : " with " + data?.bedroom+" bedrooms in " : " in "}`+data?.display_address+`${data?.price && " at AED "+data?.price?.toLocaleString()}`+""
  metadesc = "Know the details of "+data?.building.toLowerCase()+" for "+search_action+`${data?.bedroom > 0 ? isBuilding ? ' in ' : " with "+ data?.bedroom+" bedrooms in " : " in "}`+data?.display_address+`${data?.price && " at AED "+data?.price?.toLocaleString()}`+". Book a viewing to get assistance in finding the right "+data?.building.toLowerCase()+" for you."
  let processedImages = JSON.stringify({});
  seo_image_url = data?.images && data?.images.length > 0 && data?.images[0].url;
  if (data?.imagetransforms?.images_Transforms) {
    processedImages = data?.imagetransforms.images_Transforms;
    var resized_images = JSON.parse(processedImages);
    const SrcCftle = data?.images && data?.images.length > 0 && data?.images[0].url.substring(data?.images && data?.images.length > 0 && data?.images[0].url.indexOf(STAGE_ENV));
    const ProcessedUrl = resized_images[SrcCftle] && resized_images[SrcCftle]?.jpg ? resized_images[SrcCftle]?.jpg['440x288'] : data?.images && data?.images.length > 0 && data?.images[0].url;
    //setResizedImage(ProcessedUrl);              
    seo_image_url = ProcessedUrl
  }


  return (
    <>
      <Header additionalClass="white" />                   
        <SEO title={metatitle} description={metadesc} image={seo_image_url} />
        <PropertyBar propertyData={data} />
        <div style={{minHeight : bannerHeight}}>
        { renderComponent &&
        
          <PropertyBanner propertyData={data} />
        }
        </div>
        <div className={`details-container ${data?.search_type === "sales" ? "sale-details" : "rent-details"}`} >
          <Container>
            <Row>
              <Col md={12} lg={8} xl={8}>
             
                <PropertyInfo
                  propertyData={data}
                  userObjects={props?.userObjects}
                  renderComponent={renderComponent}
                />
                 {(renderComponent || isMobile == false ) &&
                  <PropertyDetails propertyData={data} renderComponent={renderComponent} />
                 }
                
                {data?.amenities && ( <>
                  {(renderComponent || isMobile == false ) &&
                  <Facility amenities={data?.amenities} />
                  }</>
                )}
                {/* {windowWidth < 768 ? <PropertyMap /> : <AboutProperty />} */}
                {data?.latitude && data?.longitude && renderComponent && (
                  <PropertyMap
                    address={data?.address}
                    lat={data?.latitude}
                    lng={data?.longitude}
                  />
                )}
                <div className="stop-sticky"></div>
                {renderComponent && <AboutProperty community={data?.community} /> }
                {data?.search_type == "sales" && (
                  <div className="financing-property">
                    <h3 className="calulators-title">Financing this property</h3>
                    <Tabs defaultActiveKey="first" className="calc-sec">
                      
                      <Tab eventKey="first" title="Mortgage Calculator">
                      { (renderComponent || isMobile == false ) &&
                        <MortgageRepayments price={data?.price} />
                      }
                      </Tab>
                      <Tab
                        eventKey="profile"
                        title="Rental Yield Calculator"
                      >
                        <RentalYield price={data?.price} />
                      </Tab>
                    </Tabs>
                  </div>
                  
                )}
                {/* {data?.search_type =="lettings" &&
        <RentalYield  price={data?.price}/>
        } */}
              </Col>
              <Col lg={4} xl={4}>
                <Sticky top=".header" bottomBoundary=".stop-sticky">
                  {renderComponent &&
                    <ContactCard
                      id={data?.id}
                      neg_email={data?.negotiator_details?.email}
                      whatsAppMessage={whatsAppMessage}
                    />
                  }
                </Sticky>
              </Col>
            </Row>
          </Container>
        </div>
        {renderComponent && <SimilarProperty
          search_type={data?.search_type}
          id={data?.id}
          address={data && data?.address}
          area={data?.community}
          userObjects={props?.userObjects}
        />
        }
        {(renderComponent || isMobile == false ) &&<>
          <Help module="Lets_Work_Together_Module" />
          <Footer />
          </>
        }
</>       
          
       
  )
}

const Property = props => {
  const [userObjects, setUserObjects] = React.useState({})
  const { state: authState, services: authServices } = useAuthState()
  const isAuthenticated = authServices.isAuthenticated()

  React.useEffect(() => {
    if (isAuthenticated) {
      const getUserObjects = async () => {
        try {
          const userObjects = await getUserobjectData({
            type: "property",
            state: true,
          })
          setUserObjects(userObjects.data?.data)
        } catch (e) {
          console.log("e:", e)
        }
      }
      getUserObjects()
    }
  }, [])

  return (
    <UserObjectStoreProvider>
      <PropertyInfoComponent {...props} userObjects={userObjects} />
    </UserObjectStoreProvider>
  )
}

export default Property
